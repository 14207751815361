<template>
  <div class="dashboard-container">
    <ConfirmDialog />
    <div class="top-nav">
      <div class="nav-left">
        <div class="left-controls">
          <div class="dark-mode-toggle">
            <button class="toggle-subtle" @click="toggleDarkMode">
              {{ isDarkMode ? '☾' : '☀' }}
            </button>
          </div>
          <div class="sidebar-toggle" @click="toggleSidebar">
            {{ isSidebarCollapsed ? '→' : '←' }}
          </div>
        </div>
      </div>
      <div class="nav-center">
        <div class="app-links">
          <a href="#" @click.prevent="showSketch = false" :class="{ active: !showSketch }">Dashboard</a>
          <a href="#" @click.prevent="showSketch = true" :class="{ active: showSketch }">Sketch</a>
        </div>
        <div class="dropdown">
          <button class="dropdown-button">Links ▾</button>
          <div class="dropdown-content">
            <a href="x-apple-reminderkit://">Reminders</a>
            <a href="obsidian://open">Obsidian</a>
            <a href="ms-outlook://">Outlook</a>
            <a href="https://ankiuser.net/study" target="_blank">Anki</a>
          </div>
        </div>
      </div>
      <div class="nav-right">
        <div class="user-auth">
          <div v-if="user" class="user-dropdown">
            <button class="user-dropdown-button">
              {{ user.displayName }} ▾
            </button>
            <div class="user-dropdown-content">
              <a href="#" @click.prevent="showSettings = true">Profile Settings</a>
              <a href="#" @click.prevent="signOut" class="sign-out">Sign Out</a>
            </div>
          </div>
          <button v-else @click="signIn">Sign In with Google</button>
        </div>
      </div>
    </div>
    <div class="left-panel" :class="{ 'collapsed': isSidebarCollapsed }">
      <div class="daily-plan-header">
        <h4>Daily Plan</h4>
      </div>
      <div class="plan-column" ref="planColumn">
        <div v-for="hour in visibleHours" :key="hour" class="hour-block" :class="{ 'current-hour': hour === currentHour }" :ref="setHourRef">
          <span class="hour-label">{{ hour }}:00</span>
          <div 
            class="task-display"
            @click="openModal(hour)"
          >
            {{ dailyPlan[hour] || '' }}
          </div>
        </div> 
      </div>
      <div class="clear-plan-button">
        <button @click="confirmClearDailyPlan" title="Clear Daily Plan">clear plan </button>
      </div>
      <!-- Keep Pomodoro timer here -->
      <div id="pomodoro">
        <button id="startTimer" @click="togglePomodoroTimer">
          <span :class="{ rocking: isTimerRunning && !isTimerPaused }">🍅</span> 
          {{ isTimerRunning ? (isTimerPaused ? 'Resume' : 'Pause') : 'Start' }} 
          <span id="timerDisplay">{{ formattedTime }}</span>
        </button>
        <button id="resetTimer" @click="resetTimer">Reset</button>
        <div id="countDisplay">Completed 🍅: {{ '✓'.repeat(completedCount) }}</div>
      </div>
      <div id="weather">
        <div v-if="weather">
          <p>{{ weather.name }} {{ weather.main.temp }}° {{ weather.weather[0].description }}.</p>
          <p>Made with love by <a href="https://plans.lol" style="text-transform: lowercase;">plans.lol</a> 😂</p>
        </div>
        <div v-else>
          <p>{{ weatherApiKey ? 'Loading weather data...' : 'Weather API key not configured' }}</p>
        </div>
      </div>
    </div>
    <div class="main-content">
      <h4>Management Dashboard</h4>
      <p>This is based on Jamie Dimon's management technique for organizing his day.</p>
      <div v-if="!showSketch">
        <div class="quadrants">
          <div class="quadrant" v-for="(item, index) in texts" :key="index">
            <label :for="item.id">{{ item.name }}</label>
            <textarea 
              :id="item.id"
              v-model="item.content" 
              @input="saveTexts" 
              :placeholder="item.placeholder || ''"
            ></textarea>
          </div>
        </div>
        <ClockifyTimer />
        <div v-if="githubUsername" class="calendar">
          <div id="github-calendar-container"></div>
        </div>
      </div>
      <SketchPage v-else />
    </div>
    <TaskModal 
      :show="showModal"
      :hour="selectedHour"
      :initialTask="dailyPlan[selectedHour] || ''"
      @save="saveTask"
      @close="closeModal"
    />
    <!-- Add Modal for Profile Settings -->
    <div v-if="showSettings" class="modal-overlay" @click.self="showSettings = false">
      <div class="modal-content">
        <ProfileSettings 
          @clockify-key-updated="refreshClockify"
          @weather-key-updated="refreshWeather"
          @github-username-updated="refreshGithub"
        />
        <button class="close-button" @click="showSettings = false">Close</button>
      </div>
    </div>
    <AmbientPlayer />
  </div>
</template>

<script>
import '../styles.css';
import { ref, onMounted, watch, nextTick } from 'vue';
import { getDatabase, ref as dbRef, onValue, set, get } from 'firebase/database';
import { fetchWeather } from '../services/weatherService';
import { getAuth, signInWithPopup, GoogleAuthProvider, signOut, onAuthStateChanged } from 'firebase/auth';
import TaskModal from './TaskModal.vue';
import { useConfirm } from "primevue/useconfirm";
import ConfirmDialog from 'primevue/confirmdialog';
import { usePermission } from '@vueuse/core';
import { initializeApp } from 'firebase/app';
import { firebaseConfig } from '../firebaseConfig';
import GitHubCalendar from 'github-calendar';
import 'github-calendar/dist/github-calendar-responsive.css';
import confetti from 'canvas-confetti';
import ClockifyTimer from './ClockifyTimer.vue';
import ProfileSettings from './ProfileSettings.vue';
import SketchPage from './SketchPage.vue'
import AmbientPlayer from './AmbientPlayer.vue';

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getDatabase(app);

export default {
    components: {
        TaskModal,
        ConfirmDialog,
        ClockifyTimer,
        ProfileSettings,
        SketchPage,
        AmbientPlayer
    },
    setup() {
        const confirm = useConfirm();
        const notificationPermission = usePermission('notifications');
        const githubUsername = ref(null);

        const loadUserProfile = () => {
          const user = auth.currentUser;
          if (user) {
            const userProfileRef = dbRef(db, `users/${user.uid}/profile`);
            onValue(userProfileRef, (snapshot) => {
              const profile = snapshot.val();
              if (profile && profile.githubUsername) {
                githubUsername.value = profile.githubUsername.replace('@', ''); // Remove @ if present
                console.log('GitHub username loaded:', githubUsername.value);
              } else {
                githubUsername.value = null;
                console.log('No GitHub username found in profile');
              }
            });
          }
        };

        const initGitHubCalendar = () => {
          if (githubUsername.value) {
            console.log('Initializing GitHub Calendar for:', githubUsername.value);
            try {
              GitHubCalendar("#github-calendar-container", githubUsername.value, { 
                responsive: true,
                tooltips: true
              }).then(() => {
                console.log('GitHub Calendar initialized successfully');
              }).catch((error) => {
                console.error('Error initializing GitHub Calendar:', error);
              });
            } catch (error) {
              console.error('Error in GitHubCalendar function:', error);
            }
          } else {
            console.log('Cannot initialize GitHub Calendar: No username available');
          }
        };

        onMounted(() => {
          console.log('ManagementQuadrant mounted');
          loadUserProfile();
        });

        watch(githubUsername, (newValue) => {
          if (newValue) {
            console.log('GitHub username changed, reinitializing calendar');
            nextTick(() => {
              initGitHubCalendar();
            });
          }
        });

        return {
            confirm,
            notificationPermission,
            githubUsername,
            auth,
            db,
            initGitHubCalendar
        };
    },
    data() {
        return {
            texts: [
                { id: 'todos', name: 'Today\'s Todos', content: '', placeholder: 'List your tasks for today...' },
                { id: 'praise', name: 'Who should I praise?', content: '', placeholder: 'Think of someone who deserves recognition...' },
                { id: 'avoid', name: 'What shouldn\'t I get?', content: '', placeholder: 'Note things that should have been delegated or reassigned...' },
                { id: 'counsel', name: 'Who should I counsel?', content: '', placeholder: 'Consider who might need your advice or support...' }
            ],
            timer: null,
            timeLeft: this.getSavedTimeLeft(), // Initialize with saved time or default
            completedCount: 0,
            weather: null,
            zipCode: null,
            weatherApiKey: process.env.VUE_APP_WEATHER_API_KEY, // Use environment variable
            isDarkMode: false,
            systemPrefersDark: false,
            user: null,
            isTimerRunning: false,
            isTimerPaused: false,
            hours: Array.from({ length: 24 }, (_, i) => i), // Array of hours from 0 to 23
            dailyPlan: {}, // Object to hold tasks for each hour
            currentHour: this.getCurrentHour(),
            showModal: false,
            selectedHour: null,
            scrollTimeout: null,
            hourRefs: {},
            updateCurrentHourInterval: null,
            lastResetDate: null,
            isSidebarCollapsed: false,
            showSettings: false,
            showSketch: false,
            tabHiddenTime: null
        }
    },
    computed: {
        formattedTime() {
            let minutes = Math.floor(this.timeLeft / 60);
            let seconds = this.timeLeft % 60;
            return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
        },
        filteredTexts() {
            return this.texts.filter(item => item.name !== 'Daily Plan');
        },
        visibleHours() {
          const currentHour = this.getCurrentHour();
          const startHour = Math.max(0, currentHour - 2);
          const endHour = Math.min(23, currentHour + 8);
          return this.hours.filter(hour => hour >= startHour && hour <= endHour);
        }
    },
    methods: {
        startPomodoroTimer() {
            if (this.timer) {
                cancelAnimationFrame(this.timer);
            }
            
            this.isTimerRunning = true;
            this.isTimerPaused = false;
            
            let lastTime = performance.now();
            const timerLoop = (currentTime) => {
                if (!this.isTimerRunning || this.isTimerPaused) return;
                
                const deltaTime = currentTime - lastTime;
                if (deltaTime >= 1000) { // Update every second
                    if (this.timeLeft > 0) {
                        this.timeLeft -= 1;
                        this.saveTimerState();
                        lastTime = currentTime;
                    } else {
                        this.completePomodoro();
                        return;
                    }
                }
                
                this.timer = requestAnimationFrame(timerLoop);
            };
            
            this.timer = requestAnimationFrame(timerLoop);
            
            // Add visibility change handler
            document.addEventListener('visibilitychange', this.handleVisibilityChange);
        },
        handleVisibilityChange() {
            if (document.hidden) {
                // Tab is hidden, store the time
                this.tabHiddenTime = Date.now();
            } else {
                // Tab is visible again, adjust timer
                if (this.tabHiddenTime && this.isTimerRunning && !this.isTimerPaused) {
                    const elapsedSeconds = Math.floor((Date.now() - this.tabHiddenTime) / 1000);
                    this.timeLeft = Math.max(0, this.timeLeft - elapsedSeconds);
                    
                    if (this.timeLeft === 0) {
                        this.completePomodoro();
                    } else {
                        this.saveTimerState();
                    }
                }
                this.tabHiddenTime = null;
            }
        },
        pauseTimer() {
            if (this.timer) {
                cancelAnimationFrame(this.timer);
            }
            this.isTimerPaused = true;
            document.removeEventListener('visibilitychange', this.handleVisibilityChange);
        },
        resumeTimer() {
            this.isTimerPaused = false;
            this.startPomodoroTimer();
        },
        resetTimer() {
            if (this.timer) {
                cancelAnimationFrame(this.timer);
            }
            document.removeEventListener('visibilitychange', this.handleVisibilityChange);
            this.timeLeft = 25 * 60;
            this.isTimerRunning = false;
            this.isTimerPaused = false;
            this.tabHiddenTime = null;
            this.saveTimerState();
        },
        completePomodoro() {
            cancelAnimationFrame(this.timer);
            this.checkAndResetPomodoro();
            this.completedCount += 1;
            this.timeLeft = 25 * 60;
            this.isTimerRunning = false;
            this.isTimerPaused = false;
            this.saveTimerState();
            
            // Only show notification if permission was previously granted
            this.showNotification('Pomodoro Completed', 'Time for a break!');
            this.celebrateCompletion();

            if (document.visibilityState !== 'visible') {
                const celebrateOnFocus = () => {
                    document.removeEventListener('visibilitychange', celebrateOnFocus);
                };
                document.addEventListener('visibilitychange', celebrateOnFocus);
            }
        },
        showNotification(title, body) {
            // Only show notification if permission was previously granted
            if (Notification.permission === 'granted') {
                new Notification(title, {
                    body: body,
                    icon: '/path/to/your/icon.png'
                });
            }
        },
        saveTimerState() {
            if (this.user) {
                const userId = this.user.uid;
                const timerStateRef = dbRef(db, `users/${userId}/timerState`);
                set(timerStateRef, {
                    timeLeft: this.timeLeft,
                    isTimerRunning: this.isTimerRunning,
                    isTimerPaused: this.isTimerPaused,
                    completedCount: this.completedCount,
                    lastResetDate: this.lastResetDate,
                }).then(() => {
                    console.log('Timer state saved successfully');
                }).catch((error) => {
                    console.error('Error saving timer state:', error);
                });
            } else {
                console.warn('Cannot save timer state: User not logged in');
            }
        },
        loadTimerState() {
            if (this.user) {
                const userId = this.user.uid;
                const timerStateRef = dbRef(db, `users/${userId}/timerState`);
                onValue(timerStateRef, (snapshot) => {
                    const data = snapshot.val();
                    if (data) {
                        this.timeLeft = data.timeLeft || 25 * 60;
                        this.isTimerRunning = data.isTimerRunning || false;
                        this.isTimerPaused = data.isTimerPaused || false;
                        this.completedCount = data.completedCount || 0;
                        this.lastResetDate = data.lastResetDate || null;
                        console.log('Timer state loaded:', data);
                        this.checkAndResetPomodoro();
                    } else {
                        console.log('No timer state found, using default values');
                    }
                }, (error) => {
                    console.error('Error loading timer state:', error);
                });
            } else {
                console.warn('Cannot load timer state: User not logged in');
            }
        },
        getSavedTimeLeft() {
            const savedTime = localStorage.getItem('pomodoroTimeLeft');
            return savedTime ? parseInt(savedTime) : 25 * 60;
        },
        getSavedCompletedCount() {
            const savedCount = localStorage.getItem('pomodoroCompletedCount');
            return savedCount ? parseInt(savedCount) : 0;
        },
        saveTexts() {
            if (this.user) {
                const userId = this.user.uid;
                const quadrantsRef = dbRef(db, `users/${userId}/quadrants`);
                const quadrantsData = {
                    todos: this.texts.find(item => item.id === 'todos')?.content || '',
                    praise: this.texts.find(item => item.id === 'praise')?.content || '',
                    avoid: this.texts.find(item => item.id === 'avoid')?.content || '',
                    counsel: this.texts.find(item => item.id === 'counsel')?.content || ''
                };
                set(quadrantsRef, quadrantsData)
                    .then(() => {
                        console.log('Quadrants saved successfully.');
                    })
                    .catch((error) => {
                        console.error('Error saving quadrants:', error);
                    });
            }
        },
        loadTexts() {
            if (this.user) {
                const userId = this.user.uid;
                const quadrantsRef = dbRef(db, `users/${userId}/quadrants`);
                onValue(quadrantsRef, (snapshot) => {
                    const data = snapshot.val();
                    console.log('Data loaded from Firebase:', data); // For debugging

                    if (data) {
                        this.texts = [
                            { id: 'todos', name: 'Today\'s Todos', content: data.todos || '', placeholder: 'List your tasks for today...' },
                            { id: 'praise', name: 'Who should I praise?', content: data.praise || '', placeholder: 'Think of someone who deserves recognition...' },
                            { id: 'avoid', name: 'What shouldn\'t I get?', content: data.avoid || '', placeholder: 'Note things that should have been delegated or reassigned...' },
                            { id: 'counsel', name: 'Who should I counsel?', content: data.counsel || '', placeholder: 'Consider who might need your advice or support...' }
                        ];
                    } else {
                        // Initialize with default texts if no data exists
                        this.texts = [
                            { id: 'todos', name: 'Today\'s Todos', content: '', placeholder: 'List your tasks for today...' },
                            { id: 'praise', name: 'Who should I praise?', content: '', placeholder: 'Think of someone who deserves recognition...' },
                            { id: 'avoid', name: 'What shouldn\'t I get?', content: '', placeholder: 'Note things that should have been delegated or reassigned...' },
                            { id: 'counsel', name: 'Who should I counsel?', content: '', placeholder: 'Consider who might need your advice or support...' }
                        ];
                    }
                });
            }
        },
        saveDailyPlan() {
          if (this.user) {
            const userId = this.user.uid;
            const dailyPlanRef = dbRef(db, `users/${userId}/dailyPlan`);
            set(dailyPlanRef, this.dailyPlan)
              .then(() => {
                console.log('Daily plan saved successfully');
              })
              .catch((error) => {
                console.error('Error saving daily plan:', error);
              });
          } else {
            console.warn('Cannot save daily plan: User not logged in');
          }
        },
        loadDailyPlan() {
          if (this.user) {
            const userId = this.user.uid;
            const dailyPlanRef = dbRef(db, `users/${userId}/dailyPlan`);
            onValue(dailyPlanRef, (snapshot) => {
              const data = snapshot.val();
              if (data) {
                this.dailyPlan = data;
                console.log('Daily plan loaded:', this.dailyPlan);
              } else {
                this.dailyPlan = {};
                console.log('No daily plan data found, initialized empty object');
              }
            });
          } else {
            console.warn('Cannot load daily plan: User not logged in');
          }
        },
        handleDailyPlanInput(hour) {
            console.log(`Updating plan for hour: ${hour}`);
            this.saveDailyPlan();
        },
        async getWeatherApiKey() {
          const auth = getAuth();
          const user = auth.currentUser;
          if (user) {
            const db = getDatabase();
            const userProfileRef = dbRef(db, `users/${user.uid}/profile`);
            try {
              const snapshot = await get(userProfileRef);
              if (snapshot.exists() && snapshot.val().weatherKey) {
                return snapshot.val().weatherKey;
              }
            } catch (error) {
              console.error('Error getting Weather API key:', error);
            }
          }
          return process.env.VUE_APP_WEATHER_API_KEY;
        },
        async fetchWeather() {
          try {
            if (!this.zipCode) {
              console.log('No ZIP code set');
              return;
            }

            const weatherData = await fetchWeather(this.zipCode);
            this.weather = weatherData;
          } catch (error) {
            console.error('Weather API Error:', error);
            this.weather = null;
            // Show error message to user
            if (error.message === 'Weather API key not configured') {
              this.weatherError = 'Please configure your Weather API key in Profile Settings';
            } else {
              this.weatherError = 'Failed to fetch weather data';
            }
          }
        },
        async getUserZipCode() {
            if (!this.user) return null;
            
            try {
                const userProfileRef = dbRef(db, `users/${this.user.uid}/profile`);
                const snapshot = await get(userProfileRef);
                return snapshot.exists() ? snapshot.val().zipCode : null;
            } catch (error) {
                console.error('Error fetching user zip code:', error);
                return null;
            }
        },
        toggleDarkMode() {
            this.isDarkMode = !this.isDarkMode;
            document.body.classList.toggle('dark-mode', this.isDarkMode);
            // Save the preference to localStorage
            localStorage.setItem('darkMode', JSON.stringify(this.isDarkMode));
            this.updateGitHubCalendarTheme();
        },
        updateGitHubCalendarTheme() {
            if (this.githubUsername) {
                console.log('Updating GitHub Calendar theme');
                try {
                    GitHubCalendar("#github-calendar-container", this.githubUsername, { 
                        responsive: true,
                        tooltips: true,
                        theme: this.isDarkMode ? 'dark' : 'light'
                    }).then(() => {
                        console.log('GitHub Calendar theme updated successfully');
                    }).catch((error) => {
                        console.error('Error updating GitHub Calendar theme:', error);
                    });
                } catch (error) {
                    console.error('Error in GitHubCalendar function:', error);
                }
            } else {
                console.log('Cannot update GitHub Calendar theme: No username available');
            }
        },
        initializeAuth() {
          onAuthStateChanged(auth, (user) => {
            this.user = user;
            if (user) {
              console.log('User logged in, loading daily plan');
              this.loadDailyPlan();
              this.loadTexts();
            } else {
              console.log('User logged out, resetting daily plan');
              this.dailyPlan = {};
              this.resetTexts();
            }
          });
        },
        async signIn() {
            const provider = new GoogleAuthProvider();
            try {
                await signInWithPopup(auth, provider);
            } catch (error) {
                console.error('Error signing in:', error);
            }
        },
        async signOut() {
            try {
                await signOut(auth);
                this.resetTexts();
            } catch (error) {
                console.error('Error signing out:', error);
            }
        },
        resetTexts() {
            this.texts.forEach(text => text.content = '');
        },
        async togglePomodoroTimer() {
            // Request notification permission when user starts the timer
            if (Notification.permission === 'default') {
                await Notification.requestPermission();
            }
            
            if (this.isTimerRunning) {
                if (this.isTimerPaused) {
                    this.resumeTimer();
                } else {
                    this.pauseTimer();
                }
            } else {
                this.startPomodoroTimer();
            }
        },
        async requestNotificationPermission() {
            try {
                const permission = await Notification.requestPermission();
                console.log('Notification permission:', permission);
            } catch (error) {
                console.error('Error requesting notification permission:', error);
            }
        },
        getCurrentHour() {
            return new Date().getHours();
        },
        updateCurrentHour() {
            const newCurrentHour = this.getCurrentHour();
            if (newCurrentHour !== this.currentHour) {
                this.currentHour = newCurrentHour;
                this.$nextTick(() => {
                    this.scrollToCurrentHour();
                });
            }
        },
        scrollToCurrentHour() {
            const currentHourBlock = this.hourRefs[this.currentHour];
            const planColumn = this.$refs.planColumn;
            
            if (currentHourBlock && planColumn) {
                const scrollPosition = currentHourBlock.offsetTop - planColumn.clientHeight / 2 + currentHourBlock.clientHeight / 2;
                planColumn.scrollTop = scrollPosition;
            }
        },
        handleResize() {
            if (this.scrollTimeout) {
                clearTimeout(this.scrollTimeout);
            }
            this.scrollTimeout = setTimeout(() => {
                this.scrollToCurrentHour();
            }, 100);
        },
        openModal(hour) {
            this.selectedHour = hour;
            this.showModal = true;
        },
        closeModal() {
            this.showModal = false;
            this.selectedHour = null;
        },
        saveTask({ hour, task }) {
          this.dailyPlan[hour] = task;
          this.saveDailyPlan();
        },
        handleKeyDown(e) {
            if (e.key === 'Escape' && this.showModal) {
                this.closeModal();
            }
        },
        confirmClearDailyPlan() {
            this.confirm.require({
                message: 'Are you sure you want to clear the entire daily plan?',
                header: 'Confirmation',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.clearDailyPlan();
                },
                reject: () => {
                    // Optional: handle rejection
                }
            });
        },
        clearDailyPlan() {
          this.dailyPlan = {};
          this.saveDailyPlan();
        },
        adjustHeight(event) {
            const textarea = event.target;
            textarea.style.height = 'auto';
            textarea.style.height = `${textarea.scrollHeight}px`;
        },
        setHourRef(el) {
            if (el) {
                const hour = parseInt(el.querySelector('.hour-label').textContent);
                this.hourRefs[hour] = el;
            }
        },
        async loadUserProfile() {
          const user = auth.currentUser;
          if (user) {
            const userProfileRef = dbRef(db, `users/${user.uid}/profile`);
            try {
              const snapshot = await get(userProfileRef);
              if (snapshot.exists()) {
                const profile = snapshot.val();
                this.zipCode = profile.zipCode;
                if (this.zipCode) {
                  await this.fetchWeather();
                }
              }
            } catch (error) {
              console.error('Error loading user profile:', error);
            }
          }
        },
        celebrateCompletion() {
          const scalar = 2;
          const unicorn = confetti.shapeFromText({ text: '😂', scalar });

          const defaults = {
            spread: 360,
            ticks: 60,
            gravity: 0,
            decay: 0.96,
            startVelocity: 20,
            shapes: [unicorn],
            scalar
          };

          const shoot = () => {
            confetti({
              ...defaults,
              particleCount: 30
            });

            confetti({
              ...defaults,
              particleCount: 5,
              flat: true
            });

            confetti({
              ...defaults,
              particleCount: 15,
              scalar: scalar / 2,
              shapes: ['circle']
            });
          };

          shoot();
          setTimeout(shoot, 100);
          setTimeout(shoot, 200);
        },
        // New method to expose completePomodoro to the global scope
        exposeCompletePomodoroToConsole() {
          window.completePomodoro = this.completePomodoro.bind(this);
          console.log('You can now call completePomodoro() from the console to test timer completion.');
        },
        checkAndResetPomodoro() {
          const today = new Date().toDateString();
          if (this.lastResetDate !== today) {
            this.completedCount = 0;
            this.lastResetDate = today;
            this.saveTimerState();
            console.log('Pomodoro count reset for new day');
          }
        },
        toggleSidebar() {
          this.isSidebarCollapsed = !this.isSidebarCollapsed;
        },
        setOneMinuteTimer() {
            this.timeLeft = 60; // Set to 60 seconds (1 minute)
            console.log('Timer set to 1 minute for testing');
        },
        // Add this to expose the method to the console
        exposeDebugCommands() {
            window.setOneMinuteTimer = this.setOneMinuteTimer.bind(this);
            window.completePomodoro = this.completePomodoro.bind(this);
            console.log('Debug commands available:');
            console.log('- setOneMinuteTimer() - Sets timer to 1 minute');
            console.log('- completePomodoro() - Completes current pomodoro');
        },
        refreshClockify() {
          // Implement refresh logic for Clockify component
        },
        refreshWeather() {
          // Implement refresh logic for weather data
        },
        refreshGithub() {
          // Reload GitHub calendar with new username
          this.loadUserProfile();
        },
        toggleSketch() {
          this.showSketch = !this.showSketch;
        }
    },
    mounted() {
        this.loadTexts();
        this.fetchWeather();
        window.vueInstance = this;  // Add this line
        
        // Check if there's an active timer and resume it
        if (this.timeLeft < 25 * 60) {
            this.startPomodoroTimer();
        }

        // Load the saved preference from localStorage
        const savedDarkMode = localStorage.getItem('darkMode');
        if (savedDarkMode !== null) {
            this.isDarkMode = JSON.parse(savedDarkMode);
            document.body.classList.toggle('dark-mode', this.isDarkMode);
        }

        this.initializeAuth();
        
        // Move loadTimerState to be called after user authentication
        onAuthStateChanged(auth, (user) => {
          if (user) {
            this.user = user;
            this.loadTimerState(); // Load timer state as soon as user is authenticated
            this.loadDailyPlan();
          } else {
            this.user = null;
            // Reset timer state when user logs out
            this.timeLeft = 25 * 60;
            this.isTimerRunning = false;
            this.isTimerPaused = false;
            this.completedCount = 0;
            this.lastResetDate = null;
          }
        });

        // Update current hour every minute
        this.updateCurrentHourInterval = setInterval(this.updateCurrentHour, 60000);

        document.addEventListener('keydown', this.handleKeyDown);

        // Add this to automatically resize textareas
        this.$nextTick(() => {
            document.querySelectorAll('.quadrant textarea').forEach(textarea => {
                textarea.addEventListener('input', this.adjustHeight);
                
                // Initial resize
                textarea.style.height = 'auto';
                textarea.style.height = `${textarea.scrollHeight}px`;
            });
        });

        this.$nextTick(() => {
            this.scrollToCurrentHour();
        });
        window.addEventListener('resize', this.handleResize);
        this.initGitHubCalendar();
        this.loadUserProfile(); // Load user profile (including zip code) when component mounts
        this.exposeCompletePomodoroToConsole();
        this.loadTimerState();
        // Set up an interval to check and reset the Pomodoro count
        setInterval(this.checkAndResetPomodoro, 60000); // Check every minute

        // Load the daily plan when the component is mounted
        // this.loadDailyPlan();

        this.exposeDebugCommands(); // Add this line to the mounted hook
    },
    beforeUnmount() {
        document.removeEventListener('keydown', this.handleKeyDown);
        document.querySelectorAll('.quadrant textarea').forEach(textarea => {
            textarea.removeEventListener('input', this.adjustHeight);
        });
        window.removeEventListener('resize', this.handleResize);
        if (this.scrollTimeout) {
            clearTimeout(this.scrollTimeout);
        }
        clearInterval(this.updateCurrentHourInterval);
        if (this.timer) {
            cancelAnimationFrame(this.timer);
        }
        document.removeEventListener('visibilitychange', this.handleVisibilityChange);
    },
    watch: {
        // Add watcher for when weather API key is updated
        async user(newUser) {
          if (newUser) {
            await this.loadUserProfile();
          }
        },
        // Add watcher for ProfileSettings updates
        showSettings(newVal) {
          if (!newVal) {
            // When settings modal is closed, reload profile and weather
            this.loadUserProfile();
          }
        }
    }
};
</script>

<style scoped>
/* ... other styles ... */

.calendar {
  background-color: var(--background-color);
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}

#github-calendar-container {
  max-width: 100%;
  overflow-x: auto;
}

/* Dark mode styles for GitHub calendar */
:global(.dark-mode) .calendar {
  background-color: #0A1929;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

:global(.dark-mode) .js-calendar-graph-svg {
  color: #8b949e !important;
}

:global(.dark-mode) .ContributionCalendar-label {
  fill: #8b949e !important;
}

:global(.dark-mode) .ContributionCalendar-day {
  fill: #161b22;
  stroke: rgba(27, 31, 35, 0.06);
}

:global(.dark-mode) .ContributionCalendar-day[data-level="0"] { fill: #161b22; }
:global(.dark-mode) .ContributionCalendar-day[data-level="1"] { fill: #0e4429; }
:global(.dark-mode) .ContributionCalendar-day[data-level="2"] { fill: #006d32; }
:global(.dark-mode) .ContributionCalendar-day[data-level="3"] { fill: #26a641; }
:global(.dark-mode) .ContributionCalendar-day[data-level="4"] { fill: #39d353; }

:global(.dark-mode) .graph-before-activity-overview {
  border-color: #30363d;
}

:global(.dark-mode) .day-tooltip {
  background-color: #161b22;
  color: #c9d1d9;
  border: 1px solid #30363d;
}

:global(.dark-mode) .text-muted {
  color: #8b949e !important;
}

/* Additional styles for better dark mode integration */
:global(.dark-mode) .calendar h4 {
  color: #fff;
}

:global(.dark-mode) .calendar text {
  fill: #8b949e !important;
}

:global(.dark-mode) .calendar .contrib-legend {
  color: #8b949e;
}

.clear-plan-button {
  margin-bottom: 10px;
  text-align: right; /* This will align the button to the right */
  display: flex;
  justify-content: flex-end; /* This will push the button to the right */
}

.clear-plan-button button {
  background-color: transparent;
  border: none;
  color: var(--text-color);
  cursor: pointer;
  font-size: 14px;
  padding: 5px 10px;
  display: inline-flex; /* Changed from flex to inline-flex */
  align-items: center;
  transition: color 0.3s ease;
}

.clear-plan-button button:hover {
  color: #ff4136; /* Red color on hover */
}

.left-panel {
  width: 250px;
  border-right: 1px solid var(--border-color);
  padding: 10px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  transition: transform 0.3s ease, opacity 0.2s ease;
  position: fixed;
  top: 60px; /* Account for top nav */
  bottom: 0;
  background-color: var(--background-color);
  z-index: 900;
}

.left-panel.collapsed {
  transform: translateX(-100%);
  opacity: 0;
}

.daily-plan-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.daily-plan-header h4 {
  margin: 0;
}

.left-controls {
  display: flex;
  align-items: center;
  gap: 10px;
  height: 32px; /* Match the height of the dark mode toggle */
}

.sidebar-toggle {
  background: transparent;
  color: var(--text-color);
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50%;
  font-size: 16px;
  transition: background-color 0.2s ease;
  border: none;
}

.sidebar-toggle:hover {
  background-color: var(--border-color);
}

/* Add dark mode styles for sidebar toggle */
:global(.dark-mode) .sidebar-toggle {
  color: #fff;
}

:global(.dark-mode) .sidebar-toggle:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.top-nav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 60px;
  background-color: white;
  border-bottom: 1px solid var(--border-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  z-index: 1000;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.nav-left, .nav-center, .nav-right {
  display: flex;
  align-items: center;
  height: 100%;
}

.nav-center {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 1;
  margin-right: 20px;
  height: 100%;
}

.nav-center .app-links {
  margin: 0;
}

/* Adjust main content to account for top nav */
.dashboard-container {
  padding-top: 60px;
}

/* Dark mode styles for top nav */
:global(.dark-mode) .top-nav {
  background-color: #0A1929;
  border-bottom-color: #1E3A5F;
}

/* Adjust existing styles */
.left-panel {
  margin-top: 0; /* Remove any top margin */
  height: calc(100vh - 60px); /* Adjust height to account for top nav */
}

.main-content {
  margin-left: 270px; /* 250px width + 20px padding */
  flex-grow: 1;
  padding: 0 20px;
  transition: margin-left 0.3s ease;
  height: calc(100vh - 60px);
}

.left-panel.collapsed + .main-content {
  margin-left: 20px;
}

.toggle-subtle {
  background: transparent;
  color: var(--text-color);
  border: none;
  width: 32px;
  height: 32px;
  padding: 4px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s ease;
}

.toggle-subtle:hover {
  background-color: var(--border-color);
}

/* Dark mode specific styles */
:global(.dark-mode) .toggle-subtle {
  color: #fff;
}

:global(.dark-mode) .toggle-subtle:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

/* Update dark mode styles for quadrants */
:global(.dark-mode) .quadrant {
  background-color: #132F4C;
}

/* Update dark mode styles for left panel */
:global(.dark-mode) .left-panel {
  background-color: #0A1929;
  border-right-color: #1E3A5F;
}

/* Update dark mode styles for main content */
:global(.dark-mode) .main-content {
  background-color: #0A1929;
}

.dropdown {
  position: relative;
  display: inline-block;
  height: 100%;
  display: flex;
  align-items: center;
}

.dropdown-button {
  background: transparent;
  color: var(--text-color);
  border: none;
  padding: 8px 16px;
  cursor: pointer;
  font-size: 14px;
  border-radius: 4px;
  height: 32px;
  display: flex;
  align-items: center;
}

.dropdown-button:hover {
  background-color: var(--border-color);
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: white;
  min-width: 160px;
  box-shadow: 0 8px 16px rgba(0,0,0,0.1);
  border-radius: 4px;
  z-index: 1001;
  top: 100%;
  right: 0;
  transform: none;
}

.dropdown-content a {
  color: var(--text-color);
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  font-size: 14px;
}

.dropdown-content a:hover {
  background-color: var(--border-color);
}

.dropdown:hover .dropdown-content {
  display: block;
}

/* Dark mode styles */
:global(.dark-mode) .dropdown-button {
  color: #fff;
}

:global(.dark-mode) .dropdown-content {
  background-color: #132F4C;
}

:global(.dark-mode) .dropdown-content a {
  color: #fff;
}

:global(.dark-mode) .dropdown-content a:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.user-auth {
  height: 100%;
  display: flex;
  align-items: center;
}

.user-auth p {
  margin: 0;
  font-size: 12px;
}

.nav-right {
  display: flex;
  align-items: center;
  height: 100%;
}

.user-auth {
  height: 32px; /* Match height with dropdown button */
  display: flex;
  align-items: center;
  margin: 0;
}

.user-auth p {
  margin: 0;
  font-size: 12px;
  line-height: 32px; /* Match line height with height */
  display: flex;
  align-items: center;
  gap: 8px;
}

.user-auth button {
  height: 32px;
  padding: 0 16px;
  font-size: 12px;
  display: flex;
  align-items: center;
}

.button-link {
  font-size: 12px;
  padding: 0;
  background: none;
  border: none;
  color: inherit;
  text-decoration: underline;
  height: auto;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 600px;
  max-width: 90%;
  position: relative;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

:global(.dark-mode) .modal-content {
  background-color: #132F4C;
  color: #fff;
}

.user-dropdown {
  position: relative;
  display: inline-block;
}

.user-dropdown-button {
  background: transparent;
  color: var(--text-color);
  border: none;
  padding: 8px 16px;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 4px;
  height: 32px;
}

.user-dropdown-content {
  display: none;
  position: absolute;
  right: 0;
  background-color: white;
  min-width: 160px;
  box-shadow: 0 8px 16px rgba(0,0,0,0.1);
  border-radius: 4px;
  z-index: 1001;
}

.user-dropdown:hover .user-dropdown-content {
  display: block;
}

.user-dropdown-content a {
  color: var(--text-color);
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  font-size: 14px;
}

.user-dropdown-content a:hover {
  background-color: var(--border-color);
}

.user-dropdown-content .sign-out {
  border-top: 1px solid var(--border-color);
}

/* Dark mode styles */
:global(.dark-mode) .user-dropdown-button {
  color: #fff;
}

:global(.dark-mode) .user-dropdown-content {
  background-color: #132F4C;
}

:global(.dark-mode) .user-dropdown-content a {
  color: #fff;
}

:global(.dark-mode) .user-dropdown-content a:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

:global(.dark-mode) .user-dropdown-content .sign-out {
  border-top-color: #1E3A5F;
}

.app-links {
  display: flex;
  gap: 24px;
  margin-right: 24px;
}

.app-links a {
  color: var(--text-color);
  text-decoration: none;
  padding: 8px 0;
  border-bottom: 2px solid transparent;
  transition: all 0.2s ease;
}

.app-links a:hover {
  color: var(--primary-color);
}

.app-links a.active {
  color: var(--primary-color);
  border-bottom-color: var(--primary-color);
}

/* Dark mode styles */
:global(.dark-mode) .app-links a {
  color: #fff;
}

:global(.dark-mode) .app-links a:hover,
:global(.dark-mode) .app-links a.active {
  color: #60a5fa;
  border-bottom-color: #60a5fa;
}
</style>

