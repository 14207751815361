<template>
  <div class="ambient-player">
    <h4>Ambient Sounds</h4>
    <div class="sound-controls">
      <div class="volume-controls">
        <input 
          type="range" 
          v-model="masterVolume" 
          min="0" 
          max="1" 
          step="0.1"
          class="volume-slider"
          title="Master Volume"
        />
        <span class="volume-label">{{ Math.round(masterVolume * 100) }}%</span>
      </div>
      
      <div class="sound-grid">
        <div 
          v-for="sound in sounds" 
          :key="sound.id"
          class="sound-item"
          :class="{ active: sound.isPlaying }"
        >
          <button 
            @click="toggleSound(sound)"
            :title="sound.name"
          >
            <i :class="sound.icon"></i>
          </button>
          <input 
            type="range" 
            v-model="sound.volume" 
            min="0" 
            max="1" 
            step="0.1"
            class="sound-volume"
            @input="updateVolume(sound)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AmbientPlayer',
  data() {
    return {
      masterVolume: 0.5,
      sounds: [
        {
          id: 'rain',
          name: 'Rain',
          icon: 'fas fa-cloud-rain',
          url: '/sounds/rain.mp3',
          isPlaying: false,
          volume: 0.5,
          audio: null
        },
        {
          id: 'waves',
          name: 'Ocean Waves',
          icon: 'fas fa-water',
          url: '/sounds/waves.mp3',
          isPlaying: false,
          volume: 0.5,
          audio: null
        },
        {
          id: 'fire',
          name: 'Fireplace',
          icon: 'fas fa-fire',
          url: '/sounds/fire.mp3',
          isPlaying: false,
          volume: 0.5,
          audio: null
        },
        {
          id: 'birds',
          name: 'Birds',
          icon: 'fas fa-dove',
          url: '/sounds/birds.mp3',
          isPlaying: false,
          volume: 0.5,
          audio: null
        },
        {
          id: 'cafe',
          name: 'Cafe',
          icon: 'fas fa-coffee',
          url: '/sounds/cafe.mp3',
          isPlaying: false,
          volume: 0.5,
          audio: null
        },
        {
          id: 'wind',
          name: 'Wind',
          icon: 'fas fa-wind',
          url: '/sounds/wind.mp3',
          isPlaying: false,
          volume: 0.5,
          audio: null
        }
      ]
    }
  },
  watch: {
    masterVolume(newVolume) {
      this.sounds.forEach(sound => {
        if (sound.audio) {
          sound.audio.volume = sound.volume * newVolume;
        }
      });
    }
  },
  methods: {
    initializeAudio(sound) {
      if (!sound.audio) {
        sound.audio = new Audio(sound.url);
        sound.audio.loop = true;
        sound.audio.volume = sound.volume * this.masterVolume;
      }
    },
    toggleSound(sound) {
      this.initializeAudio(sound);
      
      if (sound.isPlaying) {
        sound.audio.pause();
        sound.isPlaying = false;
      } else {
        sound.audio.play().catch(error => {
          console.error('Error playing sound:', error);
        });
        sound.isPlaying = true;
      }
    },
    updateVolume(sound) {
      if (sound.audio) {
        sound.audio.volume = sound.volume * this.masterVolume;
      }
    },
    stopAllSounds() {
      this.sounds.forEach(sound => {
        if (sound.audio && sound.isPlaying) {
          sound.audio.pause();
          sound.isPlaying = false;
        }
      });
    }
  },
  beforeUnmount() {
    this.stopAllSounds();
  }
}
</script>

<style scoped>
.ambient-player {
  padding: 16px;
  border-radius: 8px;
  background-color: var(--bg-color);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.sound-controls {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.volume-controls {
  display: flex;
  align-items: center;
  gap: 8px;
}

.volume-slider {
  flex: 1;
}

.sound-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  gap: 16px;
}

.sound-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.sound-item button {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border: none;
  background-color: var(--primary-color);
  color: white;
  cursor: pointer;
  transition: all 0.3s ease;
}

.sound-item.active button {
  background-color: #10b981;
  transform: scale(1.1);
}

.sound-item button:hover {
  transform: scale(1.1);
}

.sound-volume {
  width: 100%;
}

:global(.dark-mode) .ambient-player {
  background-color: #132F4C;
}

:global(.dark-mode) .sound-item button {
  background-color: #2563eb;
}

:global(.dark-mode) .sound-item.active button {
  background-color: #059669;
}

input[type="range"] {
  -webkit-appearance: none;
  height: 6px;
  background: #e5e7eb;
  border-radius: 3px;
  cursor: pointer;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 16px;
  height: 16px;
  background: var(--primary-color);
  border-radius: 50%;
  cursor: pointer;
}

:global(.dark-mode) input[type="range"] {
  background: #1E3A5F;
}

:global(.dark-mode) input[type="range"]::-webkit-slider-thumb {
  background: #2563eb;
}
</style> 