<template>
  <div class="sketch-page">
    <h4>Sketch</h4>
    <div class="sketch-controls">
      <button @click="clearCanvas">Clear</button>
      <button @click="saveSketch">Save</button>
      <button @click="loadLastSketch" :disabled="!hasLastSketch">Load Last</button>
      <input type="color" v-model="penColor" title="Choose Color"/>
      <input 
        type="range" 
        v-model="penSize" 
        min="1" 
        max="20" 
        title="Brush Size"
      />
    </div>
    <div class="canvas-container">
      <div v-if="isLoading" class="loading-overlay">
        <span>Loading sketch...</span>
      </div>
      <canvas 
        ref="canvas" 
        @mousedown="startDrawing" 
        @mousemove="draw" 
        @mouseup="stopDrawing"
        @mouseleave="stopDrawing"
        @touchstart="handleTouchStart"
        @touchmove="handleTouchMove"
        @touchend="stopDrawing"
      ></canvas>
    </div>
    <div v-if="saveStatus" :class="['status-message', saveStatus.type]">
      {{ saveStatus.message }}
    </div>
  </div>
</template>

<script>
import { getDatabase, ref as dbRef, set, get } from 'firebase/database';
import { getAuth } from 'firebase/auth';

export default {
  name: 'SketchPage',
  data() {
    return {
      isDrawing: false,
      context: null,
      penColor: '#000000',
      penSize: 2,
      lastX: 0,
      lastY: 0,
      saveStatus: null,
      hasLastSketch: false,
      isLoading: true,
      autoSaveTimeout: null,
      isSaving: false
    }
  },
  async mounted() {
    const canvas = this.$refs.canvas;
    canvas.width = canvas.offsetWidth;
    canvas.height = canvas.offsetHeight;
    this.context = canvas.getContext('2d');
    this.context.lineCap = 'round';
    
    window.addEventListener('resize', this.handleResize);
    
    // Check and load last sketch
    await this.initializeCanvas();
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
    if (this.autoSaveTimeout) {
      clearTimeout(this.autoSaveTimeout);
    }
  },
  methods: {
    handleResize() {
      const canvas = this.$refs.canvas;
      const imageData = this.context.getImageData(0, 0, canvas.width, canvas.height);
      canvas.width = canvas.offsetWidth;
      canvas.height = canvas.offsetHeight;
      this.context.putImageData(imageData, 0, 0);
      this.context.lineCap = 'round';
    },
    startDrawing(event) {
      this.isDrawing = true;
      [this.lastX, this.lastY] = this.getCoordinates(event);
    },
    draw(event) {
      if (!this.isDrawing) return;
      
      const [x, y] = this.getCoordinates(event);
      this.context.beginPath();
      this.context.strokeStyle = this.penColor;
      this.context.lineWidth = this.penSize;
      this.context.moveTo(this.lastX, this.lastY);
      this.context.lineTo(x, y);
      this.context.stroke();
      
      [this.lastX, this.lastY] = [x, y];
    },
    stopDrawing() {
      this.isDrawing = false;
      
      // Clear any pending auto-save
      if (this.autoSaveTimeout) {
        clearTimeout(this.autoSaveTimeout);
      }
      
      // Set a new timeout to auto-save
      this.autoSaveTimeout = setTimeout(() => {
        if (!this.isSaving) {
          this.autoSaveSketch();
        }
      }, 1000); // Wait 1 second after stopping to save
    },
    clearCanvas() {
      const canvas = this.$refs.canvas;
      this.context.clearRect(0, 0, canvas.width, canvas.height);
      
      // Auto-save the cleared canvas
      if (this.autoSaveTimeout) {
        clearTimeout(this.autoSaveTimeout);
      }
      this.autoSaveTimeout = setTimeout(() => {
        if (!this.isSaving) {
          this.autoSaveSketch();
        }
      }, 1000);
    },
    getCoordinates(event) {
      const canvas = this.$refs.canvas;
      const rect = canvas.getBoundingClientRect();
      const x = event.clientX - rect.left;
      const y = event.clientY - rect.top;
      return [x, y];
    },
    handleTouchStart(event) {
      event.preventDefault();
      const touch = event.touches[0];
      const mouseEvent = new MouseEvent('mousedown', {
        clientX: touch.clientX,
        clientY: touch.clientY
      });
      this.startDrawing(mouseEvent);
    },
    handleTouchMove(event) {
      event.preventDefault();
      const touch = event.touches[0];
      const mouseEvent = new MouseEvent('mousemove', {
        clientX: touch.clientX,
        clientY: touch.clientY
      });
      this.draw(mouseEvent);
    },
    async saveSketch() {
      try {
        const auth = getAuth();
        if (!auth.currentUser) {
          this.showStatus('error', 'Please sign in to save sketches');
          return;
        }

        const canvas = this.$refs.canvas;
        const imageData = canvas.toDataURL('image/png');
        
        const db = getDatabase();
        const sketchRef = dbRef(db, `users/${auth.currentUser.uid}/sketches/latest`);
        
        await set(sketchRef, {
          imageData,
          timestamp: Date.now()
        });

        this.showStatus('success', 'Sketch saved successfully');
        this.hasLastSketch = true;
      } catch (error) {
        console.error('Error saving sketch:', error);
        this.showStatus('error', 'Failed to save sketch');
      }
    },
    async loadLastSketch() {
      try {
        const auth = getAuth();
        if (!auth.currentUser) {
          this.showStatus('error', 'Please sign in to load sketches');
          return;
        }

        const db = getDatabase();
        const sketchRef = dbRef(db, `users/${auth.currentUser.uid}/sketches/latest`);
        const snapshot = await get(sketchRef);

        if (snapshot.exists()) {
          const { imageData } = snapshot.val();
          const image = new Image();
          image.onload = () => {
            this.clearCanvas();
            this.context.drawImage(image, 0, 0);
          };
          image.src = imageData;
          this.showStatus('success', 'Sketch loaded successfully');
        } else {
          this.showStatus('error', 'No saved sketch found');
        }
      } catch (error) {
        console.error('Error loading sketch:', error);
        this.showStatus('error', 'Failed to load sketch');
      }
    },
    async initializeCanvas() {
      try {
        const auth = getAuth();
        if (!auth.currentUser) {
          this.isLoading = false;
          return;
        }

        const db = getDatabase();
        const sketchRef = dbRef(db, `users/${auth.currentUser.uid}/sketches/latest`);
        const snapshot = await get(sketchRef);
        
        if (snapshot.exists()) {
          const { imageData } = snapshot.val();
          const image = new Image();
          image.onload = () => {
            this.context.drawImage(image, 0, 0);
            this.hasLastSketch = true;
            this.isLoading = false;
          };
          image.src = imageData;
        } else {
          this.isLoading = false;
        }
      } catch (error) {
        console.error('Error loading initial sketch:', error);
        this.isLoading = false;
      }
    },
    async checkLastSketch() {
      try {
        const auth = getAuth();
        if (!auth.currentUser) return;

        const db = getDatabase();
        const sketchRef = dbRef(db, `users/${auth.currentUser.uid}/sketches/latest`);
        const snapshot = await get(sketchRef);
        
        this.hasLastSketch = snapshot.exists();
      } catch (error) {
        console.error('Error checking for last sketch:', error);
      }
    },
    showStatus(type, message) {
      this.saveStatus = { type, message };
      setTimeout(() => {
        this.saveStatus = null;
      }, 3000);
    },
    async autoSaveSketch() {
      try {
        this.isSaving = true;
        const auth = getAuth();
        if (!auth.currentUser) return;

        const canvas = this.$refs.canvas;
        const imageData = canvas.toDataURL('image/png');
        
        const db = getDatabase();
        const sketchRef = dbRef(db, `users/${auth.currentUser.uid}/sketches/latest`);
        
        await set(sketchRef, {
          imageData,
          timestamp: Date.now()
        });

        this.hasLastSketch = true;
      } catch (error) {
        console.error('Error auto-saving sketch:', error);
      } finally {
        this.isSaving = false;
      }
    }
  }
}
</script>

<style scoped>
.sketch-page {
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  margin-top: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.sketch-controls {
  display: flex;
  gap: 16px;
  align-items: center;
}

.canvas-container {
  position: relative;
  width: 100%;
  height: calc(100vh - 300px);
}

canvas {
  border: 1px solid var(--border-color);
  border-radius: 4px;
  cursor: crosshair;
  touch-action: none;
  width: 100%;
  height: calc(100vh - 300px);
  background-color: white;
}

/* Dark mode styles */
:global(.dark-mode) .sketch-page {
  background-color: #132F4C;
  color: #fff;
}

:global(.dark-mode) canvas {
  border-color: #1E3A5F;
  background-color: #0A1929;
}

.status-message {
  margin-top: 16px;
  padding: 8px 16px;
  border-radius: 4px;
  text-align: center;
}

.success {
  background-color: #dcfce7;
  color: #166534;
  border: 1px solid #bbf7d0;
}

.error {
  background-color: #fee2e2;
  color: #991b1b;
  border: 1px solid #fecaca;
}

:global(.dark-mode) .success {
  background-color: #064e3b;
  color: #a7f3d0;
  border-color: #065f46;
}

:global(.dark-mode) .error {
  background-color: #7f1d1d;
  color: #fecaca;
  border-color: #991b1b;
}

button {
  padding: 8px 16px;
  border-radius: 4px;
  border: none;
  background-color: var(--primary-color);
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

:global(.dark-mode) button {
  background-color: #2563eb;
}

:global(.dark-mode) button:hover:not(:disabled) {
  background-color: #1d4ed8;
}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 1;
}

:global(.dark-mode) .loading-overlay {
  background-color: rgba(10, 25, 41, 0.8);
  color: #fff;
}
</style> 